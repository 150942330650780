const toCamelCase = name => {
  return name
    .replace(/[^a-z0-9]/gi, " ")
    .split(" ")
    .reduce((t, c, i) => {
      return t + (i > 0 ? c[0].toUpperCase() + c.substring(1) : c)
    })
}

const TrackUser = (email, { props }) => {
  if (!window || !window.rudderanalytics) return
  window.rudderanalytics.identify(window.rudderanalytics.getAnonymousId(), {
    ...props,
    email,
  })
}

const TrackEvent = (event, properties) => {
  if (window?.gtag) {
    window.gtag("event", event, properties)
  }
  if (!window || !window.rudderanalytics) return
  window.rudderanalytics.track(event, properties)
}

/*
RudderStack defined identity names
- firstName
- lastName
- name
- age
- email
- phone
- address [object]
  - city
  - country
  - postalCode
  - state
  - street
- birthday
- company [object]
  - name
  - id
  - industry
  - employee_count
  - plan
- createdAt
- description
- gender
- title
- username
- website
- avatar
 */

const PersonalInfo = form => {
  const personal = {
    name: form.name || "",
    email: form.email || "",
    phone: form.phone || "",
  }
  const joinString = (s1, s2) => {
    if (!s1) return s2
    if (!s2) return s1
    return s1 + " " + s2
  }
  const extendAddress = (key, value) => {
    personal["address"] = personal["address"] || {}
    personal["address"][key] = joinString(personal["address"][key], value)
  }

  Object.keys(form).forEach(key => {
    if (!Object.hasOwnProperty.bind(form)(key)) return
    if (!key.match(/name|phone|mobile|number|email|e-mail|address|zip|postal|city|state|country/gi))
      return
    const value = form[key]
    if (key.match(/name/gi)) {
      if (key.match(/user/gi)) {
        personal["username"] = value
      } else if (key.match(/first|given|fname/gi)) {
        personal["firstName"] = value
      } else if (key.match(/last|family|lname|surname/gi)) {
        personal["lastName"] = value
      } else {
        personal[toCamelCase(key)] = value
      }
    } else if (key.match(/email|e-mail/gi)) {
      personal["email"] = value
    } else if (key.match(/address/gi)) {
      extendAddress("street", value)
    } else if (key.match(/state/gi)) {
      extendAddress("state", value)
    } else if (key.match(/zip|postal/gi)) {
      extendAddress("postalCode", value)
    } else if (key.match(/country/gi)) {
      extendAddress("country", value)
    } else if (key.match(/city/gi)) {
      extendAddress("city", value)
    } else if (key.match(/phone|mobile/gi)) {
      extendAddress("phone", value)
    } else {
      personal[toCamelCase(key)] = value
    }
  })
  if (!personal["name"] && (personal["firstName"] || personal["lastName"])) {
    personal["name"] = joinString(personal["firstName"], personal["lastName"])
  }
  return personal
}

export { PersonalInfo, TrackEvent, TrackUser }
