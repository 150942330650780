import * as React from 'react';
import {useCallback,  useState} from 'react';

const UpdateFormFactory = (setForm) => {
    return useCallback(e => {
        setForm(f => {
            let newForm = {...f};
            newForm[e.target.name] = e.target.value;
            const valid = e.target.valid;
            if (valid) {
                delete newForm['.errors'][e.target.name];
            } else {
                if (e.target.error) {
                    newForm['.errors'][e.target.name] = e.target.error;
                } else {
                    newForm['.errors'][e.target.name] = true;
                }
            }
            return newForm;
        });
    }, [setForm]);
}

const useFormState = (initialState) => {
    const [form, setForm] = useState({...initialState, ".errors": {}});
    return [form, setForm, UpdateFormFactory(setForm)];
}

export default useFormState;