import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { PersonalInfo, TrackEvent, TrackUser } from "../../helpers/TrackingHelper"
import { navigate } from "gatsby"
import traverse from "./helpers/traverse"
import validateInputs from "./helpers/validateInputs"
import getUTMCodes from "./helpers/getUTMCodes"
import FormUploadingIndicator from "./FormUploadingIndicator"
import uploadWithProgress from "./helpers/uploadWithProgress"
import extractFilesAndForm from "./helpers/extractFilesAndForm"
import PropTypes from "prop-types"

const FormWrapper = ({
  children,
  className,
  action,
  name,
  form,
  category = "",
  encType = "multipart/form-data",
  method = "POST",
  minimumTimeout = 12000,
}) => {
  const formElement = useRef()
  const [status, setStatus] = useState({
    isWorking: false,
    isPlaying: false,
    progress: 0,
    label: "",
  })
  const [person, setPerson] = useState({
    name: "",
    email: "",
    phone: "",
  })

  const scrollToForm = useCallback(() => {
    formElement.current?.scrollIntoView()
  }, [])

  useEffect(() => {
    if (status.isWorking || status.isPlaying || status.progress < 1) return
    // TrackEvent("Form", {
    //   category: "Thank You Page",
    //   label: "Thank You Page",
    // })
    navigate(action)
  }, [status])

  useEffect(() => {
    const { formData } = extractFilesAndForm(form, name || "")
    const { name: personName, email, phone } = PersonalInfo(formData)
    setPerson({ name: personName, email, phone })
  }, [form, name, extractFilesAndForm, PersonalInfo, setPerson])

  useEffect(() => {
    const nameIsEmpty = person.name === ""
    const emailIsIncorrect = person.email === "" || !person.email.includes("@")
    const phoneIsIncorrect = person.phone === "" || !person.phone.includes("+")
    const throttle = 2500
    // require name and (phone or email)
    if (nameIsEmpty || (emailIsIncorrect && phoneIsIncorrect)) {
      return
    }
    const timeout = setTimeout(() => {
      TrackUser(person.email, {
        name: person.name,
        phone: person.phone,
        email: person.email,
      })
      TrackEvent("Form Started", {
        category: "Form Fill",
        label: name,
        form: person,
      })
      const query = getUTMCodes()
      fetch("/api/send-form" + query, {
        method: "POST",
        body: JSON.stringify({
          gohighlevel: true,
          name: person.name,
          phone: person.phone,
          email: person.email,
          _formName: name,
          _qs: window?.qs,
          _initial_page: window?._initial_page,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(() => {})
        .catch(err => {
          TrackEvent("Form Fill status update Error", { message: err.message })
        })
    }, throttle)
    return () => clearTimeout(timeout)
  }, [person.name, person.email, person.phone, name])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      // check errors
      // if (form && form['.errors'] && Object.keys(form['.errors']).length > 0) {
      //   window?.alert('Please fill out all required fields');
      //   return;
      // }
      // check errors again, now traverse the form
      const inputs = traverse(children, null)
      const errors = validateInputs(inputs, form)
      if (errors.length > 0) {
        window?.alert(
          "Please fill out all required fields.\n" +
            errors[0].error +
            (errors.length > 1 ? `\r... and ${errors.length - 1} more error(s)` : "")
        )
        console.log(errors)
        return
      }

      try {
        setTimeout(() => {
          setStatus(s => {
            return { ...s, isPlaying: false }
          })
        }, minimumTimeout)
        setStatus(s => {
          return {
            ...s,
            isWorking: true,
            isPlaying: true,
            label: "Sending form header",
          }
        })
        scrollToForm()
        const { formData, files } = extractFilesAndForm(form, name)
        const totalSize = files.reduce((c, f) => c + f.file.size, 0)
        TrackUser(formData.email, PersonalInfo(formData))
        TrackEvent("Form Submission", {
          category: "Submission",
          label: name,
          form: formData,
        })
        const query = getUTMCodes()
        let info = await fetch("/api/send-form" + query, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        let json = await info.json()
        let totalSent = 0
        const updateProgress = ev => {
          let progress = (totalSent + ev.current) / totalSize
          setStatus(st => {
            return { ...st, progress }
          })
        }
        for (let { file, key, index } of files) {
          setStatus(st => {
            return {
              ...st,
              isWorking: true,
              label: `Uploading file ${file.name}. Total size: ${totalSize}`,
            }
          })
          try {
            let qs = `file=${encodeURIComponent(file.name)}&form=${encodeURIComponent(
              json.id
            )}&key=${encodeURIComponent(key)}&i=${encodeURIComponent(index)}`
            await uploadWithProgress(`/api/send-form?${qs}`, file, updateProgress)
          } catch (err) {
            console.log("Form submission error")
            console.error(err)
            TrackEvent("Form Submission Error", { message: e.message })
          }
          totalSent += file.size
        }
        setStatus(st => {
          return { ...st, progress: 100, label: "Submitting" }
        })
        /* consider reading info = */
        await fetch("/api/send-form", {
          method: "POST",
          body: JSON.stringify({ id: json.id, finished: true }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        setStatus(st => {
          return { ...st, isWorking: false }
        })
      } catch (err) {
        console.error(err.message)
        TrackEvent("Form Submission Error", { message: err.message })
      }
    },
    [
      form,
      name,
      children,
      extractFilesAndForm,
      traverse,
      validateInputs,
      scrollToForm,
      minimumTimeout,
    ]
  )

  return (
    <form
      className={className}
      name={name}
      data-netlify={"true"}
      netlify-honeypot="hpfield"
      method={method}
      action={action}
      encType={encType}
      onSubmit={handleSubmit}
      ref={formElement}
    >
      {status.isWorking || status.isPlaying ? (
        <FormUploadingIndicator status={status} category={category} />
      ) : (
        children
      )}
    </form>
  )
}
FormWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  method: PropTypes.string,
  action: PropTypes.string,
  encType: PropTypes.string,
  className: PropTypes.string,
  category: PropTypes.string,
  minimumTimeout: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default FormWrapper
