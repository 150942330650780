import * as React from 'react';
import LoadingFiles from '../products/LoadingFiles';
import IconsProductPreloader from '../products/IconsProductPreloader';

const FormUploadingIndicator = ({status, category}) => {
    // Consider using progress: const progress = Math.round(status.progress * 100, 2) + '%';
    return (
        <div>
            <h2>The form is being submitted</h2>
            <h3>{status.label}</h3>
            <IconsProductPreloader/>
            <LoadingFiles category={category}/>
        </div>
    );
};

export default FormUploadingIndicator;