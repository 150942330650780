import * as React from "react";
import {GetIcon} from "../../icons/IconLoaders"
import {IconMoneyBag, IconSearchLoop}  from './IconsProductPreloader';

const LoadingFiles = ({category}) => {
  const Icon = GetIcon(category)

  return (
    <section className="container-dl overflow-hidden">
      <div className="dotted-circle">
        <div className="solid-circle"></div>
      </div>
      <div className="circle-burst1"></div>
      <div className="circle-burst2"></div>
      <div className="circle-burst3"></div>

      <div className="lg-image-block">
      <div><IconMoneyBag/></div>
      <div><IconSearchLoop /></div>
      <div className="white-box"></div>
       <div className="h-full fade-in-dl"> {Icon}</div>       
      </div>
      
    </section>
  );
};

export default LoadingFiles;
