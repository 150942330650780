const uploadWithProgress = (url, blob, updateProgress, options = {}) => {
    const defaultOptions = {
        method: 'PUT',
        contentType: 'application/octet-stream',
    };
    options = {...defaultOptions, ...options};
    const xhr = new XMLHttpRequest();
    return new Promise(resolve => {
        xhr.upload.addEventListener('progress', event => {
            if (event.lengthComputable) {
                console.log('upload progress:', event.loaded / event.total);
                if (updateProgress) {
                    updateProgress({
                        upload: true,
                        current: event.loaded,
                        total: event.total,
                        percent: event.loaded / event.total,
                    });
                }
            }
        });
        xhr.addEventListener('progress', event => {
            if (event.lengthComputable) {
                console.log('download progress:', event.loaded / event.total);
                if (updateProgress) {
                    updateProgress({
                        upload: false,
                        current: event.loaded,
                        total: event.total,
                        percent: event.loaded / event.total,
                    });
                }
            }
        });
        xhr.addEventListener('loadend', () => {
            resolve(xhr.readyState === 4 && xhr.status === 200);
            if (updateProgress) {
                updateProgress({
                    upload: false,
                    current: 1,
                    total: 1,
                    percent: 1,
                });
            }
        });
        xhr.open(options.method, url, true);
        xhr.setRequestHeader('Content-Type', options.contentType);
        xhr.send(blob);
    });
};

export default uploadWithProgress;