const getUTMCodes = () => {
    let query = '';
    if (typeof window !== 'undefined' && window?.qs) {
        query =
            '?' +
            Object.keys(window.qs)
                .filter(k => window.qs.hasOwnProperty(k) && k.indexOf('utm_') === 0)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(window.qs[k]))
                .join('&');
    }
    return query;
}

export default getUTMCodes