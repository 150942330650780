import * as React from 'react';

const traverse = (children, _parent) => {
    return React.Children.map(children, child => {
        if (child.props?.children) {
            return traverse(child.props.children, child);
        }
        if (child.props?.name) {
            return child;
        }
        return null;
    });
}

export default traverse;