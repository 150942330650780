const validateInputs = (inputs, form) => {
    return inputs.map(input =>
        ({
            name: input.props.label,
            required: input.props.required,
            value: form[input.props.name],
            validator: input.props.validator ? input.props.validator(form[input.props.name]) : false,
            valid: true
        }))
        .map(info => {
            if (!info.required && !info.value) return info;
            if (info.validator) {
                info.valid = info.validator.isValid;
                info.error = (info.validator.message || `${info.name} is required`).replace('[label]', info.name);
            } else if (info.value) {
                // check if value is an array
                if (Array.isArray(info.value)) {
                    // check if there is at least one value
                    info.valid = (info.value.length > 0)
                    info.error = `${info.name} is required`;
                } else {
                    info.valid = true;
                }
            } else {
                info.valid = false;
                info.error = `${info.name} is required`;
            }
            return info;
        }).filter(info => !info.valid);
}

export default validateInputs