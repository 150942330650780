import * as React from 'react';
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import InputField from '../components/form/InputField';
import FormWrapper from '../components/form/FormWrapper'
import useFormState from "../components/form/hooks/useFormState";
import '../styles/main.scss'

const CurrentDevDL = () => {
    const [form, setForm, updateForm] = useFormState({
        ".errors": {},
        name: '',
        phone: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        catalog: true,
        newsletter: true
    })
    const handleChange = e => {
        setForm(f => {
            let newForm = { ...f }
            newForm[e.target.name] = e.target.checked
            return newForm
        })
    };
    useEffect(() => {
        window.collectchat = window.collectchat || {};
        if (window.collectchat.unload) {
            window.collectchat.unload();
        }
        window.collectchat.ready = () => {
            for (let i = 1; i < 10; ++i) {
                setTimeout(() => {
                    window.collectchat.unload();
                }, 500 * i)
            }
        };
    }, []
    )
    return (
        <section className='min-h-screen bg-[#d1c3a4]'>
            <div className="flex flex-col items-center justify-center  lg:flex-row">
                <div className="flex w-full justify-center">
                    <StaticImage
                        src={"../images/sell-my-jewelry-form-header.png"}
                        alt={'Sell Us Your Jewelry'}
                        loading={'eager'}
                    />
                </div>
                <div className="w-full">
                    <h1 className="p-10 text-center text-xl font-black text-black md:text-3xl xl:text-4xl">
                        Do You Have Unwanted Jewelry, Watches, Scrap Gold, Diamonds, Sterling Silver Flatware You Want To Sell For Cash?
                        <br /><br />
                        <span className='text-[rgb(99,73,33)]'>Sell / Trade / Consign</span>
                    </h1>
                    <div className="flex w-full flex-col justify-center p-10 align-middle xl:px-20 ">
                        <FormWrapper
                            className={'bg-white p-8 rounded-lg shadow-lg form-checkbox'}
                            name={'The MelK Show - Tell Us About Yourself'}
                            action={'/melk/'}
                            form={form}
                            minimumTimeout={1000}
                        >
                            <h2 className="text-center text-3xl uppercase ">Tell us about yourself!</h2>
                            <p className="text-center">
                                Fill out this simple form to initiate your request.
                            </p>
                            <div className="flex flex-col space-y-4">
                                <InputField
                                    name={'name'}
                                    label={'Name'}
                                    form={form}
                                    onChange={updateForm}
                                    required={true}
                                />
                                <InputField
                                    name={'phone'}
                                    label={'Phone'}
                                    form={form}
                                    onChange={updateForm}
                                    required={true}
                                />
                                <InputField
                                    name={'email'}
                                    label={'E-Mail'}
                                    form={form}
                                    onChange={updateForm}
                                    required={true}
                                />
                                <InputField
                                    name={'address1'}
                                    label={'Street Address'}
                                    form={form}
                                    onChange={updateForm}
                                    required={true}
                                />
                                <InputField
                                    name={'address2'}
                                    label={'Address Line 2'}
                                    form={form}
                                    onChange={updateForm}
                                />

                                <InputField
                                    name={'city'}
                                    label={'City'}
                                    form={form}
                                    onChange={updateForm}
                                    className={' col-span-2'}
                                />
                                <span className="grid grid-cols-2 gap-4">
                                    <InputField
                                        name={'state'}
                                        label={'State'}
                                        form={form}
                                        onChange={updateForm}
                                        className={'col-span-1'}
                                    />
                                    <InputField
                                        name={'zip'}
                                        label={'Zip Code'}
                                        form={form}
                                        onChange={updateForm}
                                        className={'col-span-1'}
                                    />
                                </span>
                            </div>
                            <div className='flex my-3 gap-3'>
                                <input type="checkbox" onChange={handleChange} id="catalog" name={"catalog"}
                                    checked={form['catalog']} />
                                <label htmlFor="catalog">I wish to receive my FREE monthly catalogs from Gray and
                                    Sons Jewelers.</label>
                            </div>
                            <div className='flex my-3 gap-3'>
                                <input type="checkbox" onChange={handleChange} id="email-cb" name={"newsletter"}
                                    checked={form['newsletter']} /> <label htmlFor="email-cb">Sign me up to
                                        receive special offers and discounts by EMail.</label>
                            </div>

                            <button className=" btn-cta w-[15rem] p-5">SUBMIT FORM</button>

                        </FormWrapper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CurrentDevDL;
